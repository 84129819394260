import React from 'react'
import {Box,Text,Heading,Flex} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import {StaticImage} from 'gatsby-plugin-image'


const Fake = () => {
  return(
    <>
    <Heading variant="titel" mt="10" pl="3" pt="3">Podium Szene + Dialog<br/>Sankt Werner von Oberwesel –<br/>
700 Jahre antisemitische Fake News</Heading>
   
<Flex  flexWrap="wrap" mx="4" maxWidth="1200">
<MeinSEO title="Podium Szene + Dialog "
  description="Der Historiker Walter Karbach,Peter Keber und Karl-Heinz Hartmann im Dialog. "
  />
<Box pr={["0","0","3"]} width={["100%","100%","49%"]}>


 
<StaticImage src="../images/web/teaser-7.jpg" 
   
   alt="Teaser zur Veranstaltung Sankt Werner"
   layout='constrained'
  />
 <Text>
  In Oberwesel und im benachbarten Bacharach ist die mittelalterliche antijüdische Ritualmordlegende vom Heiligen Werner beheimatet, die 1287 das große Juden­pogrom im Rheintal einleitete. Die rheinische Variante einer europaweit verbreiteten Ritualmordhetze lieferte Motive und Legitimationen für Judenverfolgungen in Deutschland und Europa bis in den vorderen Orient. Noch bis in die 1970er Jahre hinein fanden in der Region Prozessionen zu Ehren dieses von der katholischen Kirche nie kanonisierten „Volksheiligen“ statt, der als unheilvoll erfundener Märtyrer eines vorgeblichen jüdischen Rituals dann auch noch zum „Schutzpatron der Winzer“ mutierte.
   </Text>
   <Text>
   <span style={{fontWeight:500}}>Dr. Walter Karbach</span>, Lokalhistoriker und Autor der um­ fangreichen Monografie „Werner von Oberwesel: Ritual­mordlüge und Märtyrerkult“ präsentiert seine Forschung über die Erfindung des Wunsch-Heiligen und den Einsatz der Legende als Zunder für Volkszorn­-Kampagnen bis in die jüngere Geschichte hinein – und die Neuauflage von Motiven der Ritualmordlegende in aktuellen Verschwö­rungstheorien z.B. von QAnon.
   </Text>
  <Text>
  <span style={{fontWeight:400}}>Peter Keber</span>, Mitgründer und langjähriger Vorsitzender des Bauvereins Wernerkapelle Bacharach und <span style={{fontWeight:400}}>Karl-Martin Hartmann</span> , Künstler und Schöpfer der Installation „Das Fenster“ in der Ruine der Bacharacher Wernerkapelle (2007 bis 2010) , berichten über ihre bis heute in die bürgerschaftliche Selbstaufklärung vor Ort ausstrahlende künstlerische Intervention und deren Einbettung in <span style={{fontWeight:400}}>Karl­-Martin Hartmanns</span> weltweit reichendes Kunstprojekt „Stelen der Toleranz“.
  </Text>
  <Text>
  Eingeleitet wird der Dialog vom Ensemble des Theaters Willy Praml mit der Eingangsszene aus seiner Inszenierung DER RABBI VON BACHARACH. Karl­Martin Hartmanns Installation „Das Fenster“ zitierte seinerzeit in schwarzen Lettern auf rotem Glasgrund Auszüge aus Heinrich Heines gleichnamiger Erzählung.

  </Text>
   
</Box>
<Box shadow="md" bg="gray.200" p="3"  mx={[3,3,0]} flex="1 1">
     <Heading variant="titel">Podium</Heading>
    <Text variant="Title"> Dr. Walter Karbach <br/><span style={{fontWeight:300}}>Historiker</span></Text>
    <Text variant="Title"> Peter Keber<br/><span style={{fontWeight:300}}>Bauverein Wernerkapelle</span></Text> 
    <Text variant="Title"> Karl-Martin Hartmann <br/> <span style={{fontWeight:300}}>Bildener Künstler</span></Text>
    <Text variant="Title">Moderation<br/> <span style={{fontWeight:300}}>Werner Heinz</span></Text>
    <Text variant="Title">Darsteller*innen<br/> <span style={{fontWeight:300}}>Reinhold Behling, Jakob Gail, Gabriele Graf, Birgit Heuser, Ruth Klapperich, Sam Michelson, Claudio Vilardo, Michael Weber</span></Text> 
    
  <Text variant="Title" pt="6"> Bacharach</Text>
  <Text mt="2" >
  Sonntag, 17. Juni um 11:00 -  13:00 Uhr<br/> Günderodehaus, Oberwesel
  </Text>
 <Text>Bus-Shuttle zum Günderodehaus ab Bahnhof Oberwesel: 10:30 Uhr und 10:45 Uhr sowie retour nach der Matinee.</Text>
 
 
  <Box mt="12">
  <Text  mb="1">  
  
  Gefördert von:<br/>
<span style={{fontWeight:500}}>1700 Jahre Jüdisches Leben in Deutschland</span></Text>
 <Box maxWidth="120" pt="3">
 
<StaticImage src="../images/logos/leben.jpg" />
</Box>
  </Box> 
  </Box>
</Flex>
</>
  )
}
 export default Fake


  